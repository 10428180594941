/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
.str-video {
    position: relative;
    background-color: #002C2C;
    color: #002C2C;
    height: 90dvh;
    width: 95vw;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
  }

  .str-video__call-controls{
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    background-color: #0E2B2C; */
    position: relative;
    bottom: 0%;
    /* padding: 20px; */
    left: 50%;
    transform: translateX(-50%);
  }