/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
.floating-call-stats {
  position: absolute;
  /* padding: 20px; */
  left: 0%;
  top: 10%;
  transform: translateX(-50%);
  width: 300px;
  height: 900px;
  overflow: auto;
  z-index: 1;
}

.floating-call-stats.minimized {
  width: 100px;
  height: 50px;
}