/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.kicked-message {
  color: red;
  font-size: 20px;
  /* Additional styling as needed */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  5% { transform: rotate(-0.25deg); }
  10% { transform: rotate(0.25deg); }
  30% { transform: rotate(0deg); }
  40% { transform: rotate(0deg); }
  50% { transform: rotate(0deg); }
  55% { transform: rotate(0.25deg); }
  60% { transform: rotate(-0.25deg); }
  80% { transform: rotate(0deg); }
  90% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

.shake {
  animation: shake 3s cubic-bezier(.36,.07,.19,.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.callContainer {
  position: absolute;
  /* background-color: black; */
  top: 2.5vh;
  right: 10vw;
  bottom: 2.5vh;
  left: 10vw;
  width: 80vw;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: center;
}

body {
  background-color: #002C2C;
}

.joined-call {
  background-color: #002C2C !important;
}

/* Media query for laptop screens */
@media (min-width: 1024px) {
  .callContainer {
    padding: 20px;  /* Increased padding for laptop screens */
  }
}

.custom-button {
  color: #002C2C !important;
  border-color: #002C2C !important;
}

.custom-button:hover, .custom-button:active, .custom-button:focus {
  background-color: #002C2C !important;
  color: #fff !important;
  border-color: #002C2C !important;
}

.custom-button:disabled {
  /* Add your styles for the disabled state here. For example: */
  background-color: #335656 !important; /* Lighter version of #002C2C */
  color: #99A9A9 !important; /* Even lighter version for the text */
  border-color: #335656 !important; /* Same as background */
  cursor: not-allowed !important;
}