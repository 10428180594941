/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
/* From https://github.com/bitwarden/passwordless-react-example/blob/main/src/components/Menu.module.css */
/* Modify the background color */
    
.navbar-custom {
    background-color: primary-color;
}
/* Modify brand and text color */
    
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: white;
}

.nav-link-text {
    color: white !important;
    }

.nav-dropdown-text .dropdown-toggle {
    color: white !important; 
}

.header {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    background-color: #175ddc;
    padding: 0 10%;
}

.logo {
    font-size: 2rem;
    color: white;
    font-weight: bold;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
}

.header li {
    margin-left: 3rem;
}

.header a {
    text-decoration: none;
    font-size: 2rem;
    color: white;
}

.header a:hover,
.header a:active,
.header a.active {
    color: white;
}

.badge {
    background-color: #175ddc;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
}

/* Modify brand and text color */
 
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: white;
    font-size: 1.5em;
}

.nav-link-text {
    color: white !important;
    font-size: 1em;
  }

.nav-dropdown-text .dropdown-toggle {
    color: white !important; 
    font-size: 1em;
}
