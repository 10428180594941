/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
/* #container {
    width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
  } */
  
  .circleContainer {
    position: fixed;
    /* background-color: black; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  .circle {
    position: absolute;
    border-radius: 100%;
    background-color: #002C2C;
    width: 15em;
    height: 15em;
    opacity: 0;
    animation: scaleIn 12s infinite ease-in-out;
  }
  
  .item {
    position: absolute;
    z-index: 100;
    padding: 5px;
    
  }

  .item img {
    width: 150px;
  }

  @keyframes scaleIn {
    from {
      transform: scale(1, 1);
      opacity: .05;
    }
    to {
      transform: scale(4, 4);
      opacity: 0;
    }
  }