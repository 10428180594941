/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
/* from https://github.com/bitwarden/passwordless-react-example/blob/main/src/components/Layout.module.css */
@media (max-width: 600px) {
    .main {
        width: 100%;
        margin: 1rem auto;
        padding: 1rem;
    }
}

.main {
    padding: 3rem;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }

:root {
    --primary-color: #0E2B2C;
    --secondary-color: #D8EAD9;
    --white: #ffffff;
  }