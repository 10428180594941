/* Copyright © 2024 Tripp Cashel & Liam Cashel - All Rights Reserved */
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: sans-serif;
}

.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}

.floating-call-stats {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100; /* Ensure it floats above other content */
  transform: scale(0.5); /* Make it half its original size */
  cursor: move; /* Change cursor to indicate movability */
}