.home-page .banner {
    background-color: #002C2C;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    z-index: 1000;
    position: absolute;
    top: 0;
  }
  
  .home-page .logo-container {
    display: flex;
    align-items: center;
    height: 1.5rem; /* Match the height of the logo and text */
  }
  
  .home-page .banner-logo {
    height: 1.5rem; /* Match the font size of the banner title */
    width: auto; /* Maintain aspect ratio */
    margin-right: .5em; /* Fine-tune spacing between logo and text */
  }
  
  .home-page .banner-title {
    color: #D8EAD9;
    font-size: 1.5rem;
    margin: 0;
    padding-left: 0; /* Remove left padding */
    display: flex;
    align-items: center;
  }

  .home-page .banner-link {
    margin-left: auto; /* Pushes the link to the right */
  }
  
  .home-page .login-link {
    text-decoration: none;
    color: #6c757d; /* Subtle gray color */
    font-size: 0.9rem;
    padding: 5px 10px;
    border: 1px solid #6c757d;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .home-page .login-link:hover {
    background-color: #6c757d;
    color: #ffffff;
  }

/* Styles for the home page layout */

.home-page {
    font-family: 'Inter', sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: transparent;
    position: relative;
    z-index: 1;
    scroll-snap-type: y proximity;
    overflow-y: auto;
    height: auto;
    min-height: 100vh;
}

/* styles for the container, this applies to the entire page */
.home-page .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 90%;
}

/* styles for the hero section */
.home-page .hero {
    color: #D8EAD9;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-color: #002C2C;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

/* the bottom of the hero section where the light semicircle is */
.home-page .hero::after {
    content: '';
    position: absolute;
    bottom: -15vh;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
    height: 30vh;
    background-color: #D8EAD9;
    border-radius: 50% 50% 0 0;
}

/* puts the home page on top of the background */
.home-page .hero .container {
    position: relative;
    z-index: 2;
}
  
  .home-page .hero .container {
    position: relative;
    z-index: 2;
    max-width: 800px;
  }
  
  /* font for hero title */
  .home-page .hero h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
  }
  
  /* font for hero text */
  .home-page .hero p {
    font-size: 1.3rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* this is the highlight color for the hero text */
  .home-page .hero .highlight {
    color: #B8D8BC;
    font-weight: 700;
  }

/* the button on the hero section, also includes light animation for the button click */
.home-page .cta-button {
  background-color: #D4EBD8;
  color: #0E2B2C;
  border: none;
  padding: 14px 28px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* hovering effect over the hero button */
.home-page .cta-button:hover {
  background-color: #B8D8BC;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* general section css */
.home-page .section-title {
    font-size: 2rem;
    font-weight: 700;
    color: #0E2B2C;
    text-align: center;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

/* Features class */

.home-page .features, .home-page .how-it-works, .home-page .testimonials, .home-page .cta, .home-page .current-methods, .home-page .otp-process, .home-page .wavesign-process {
  padding: 100px 0;
}

/* Background color formatting for the light color sections */
.home-page .features, .how-it-works, .current-methods {
  background-color: #D8EAD9;
}

.home-page .feature-prefix {
    font-size: 1.2rem;
    color: #999;
    font-weight: normal;
  }

  /* general h2 for the page */
.home-page .features h2, .home-page .how-it-works h2, .home-page .testimonials h2, .home-page .cta h2, .home-page .current-methods h2, .home-page .otp-process h2, .home-page .wavesign-process h2 {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.5rem;
  font-weight: 700;
  color: #0E2B2C;
}
/* section title specific styles */
.home-page .current-methods h2.section-title, .home-page .features h2.section-title, .home-page .how-it-works h2.section-title, .home-page .testimonials h2.section-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #0E2B2C;
    text-align: center;
    margin-bottom: 40px;
}

/* general grid styles */
.home-page .feature-grid, .home-page .testimonial-grid, .home-page .method-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
}

/* general item styles */
.home-page .feature-item, .home-page .testimonial-item, .home-page .method-item {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 3),
                box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.home-page .feature-item, .home-page .testimonial-item {
    height: 250px;
}
/* animation for feature items to jump/move up when hovering over them */
.home-page .feature-item {
    /* ... existing styles ... */
    transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 3),
                box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 3);
  }
  
  .home-page .feature-item.animate {
    transform: translateY(-30px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
  
  @media (min-width: 769px) {
    .home-page .feature-item:hover {
      transform: translateY(-10px);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    }
  }

/* Format for the method section */

.home-page .method-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  /* specific method-item styles */
  .home-page .method-item {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  
  .home-page .method-content {
    flex: 1;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .home-page .method-item h3 {
    margin-bottom: 5px;
    color: #0E2B2C;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .home-page .method-subheader {
    color: #666;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .home-page .method-description p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0;
  }
  
  /* this might be duplicitive with the above risk grid */
  .home-page .risks-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    width: 200px;
    flex-shrink: 0;
    padding-top: 15px;
  }
  /* risk item styles */
  .home-page .risk-item {
    background: rgba(255, 0, 0, 0.15);
    backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 8px;
    font-size: 0.8rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 60px;
    position: relative;
    overflow: visible;
  }
  /* the exclamation point for the risk item */
  .home-page .risk-item::after {
    content: '!';
    position: absolute;
    top: -10px;
    left: -5px;
    width: 20px;
    height: 20px;
    background-color: #ff0000;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    z-index: 2;
  }

  @media (max-width: 600px) {
    .home-page .risk-item {
      width: 100%; /* Flexible width for smaller screens */
    }
  }
  
  @media (max-width: 900px) {
    .home-page .method-item {
      flex-direction: column;
      padding: 20px;
    }
  
    .home-page .method-content {
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .home-page .risks-grid {
      width: 100%;
    }
  }
  
  @media (max-width: 600px) {
    .home-page .method-item {
      padding: 15px;
    }
  }

  /* this is the cool hover animation where the box jumps up */
.home-page .feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

/* this is the animation for the border that follows your mouse around */
.home-page .feature-item::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 12px;
    background: radial-gradient(
        circle at var(--gradient-x, 50%) var(--gradient-y, 50%),
        #0E2B2C 0%,
        #0E2B2C 30%,
        transparent 55.1%
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    padding: 3px;
    opacity: 0;
    transition: opacity 0.3s;
}

/* this is part of the animation for the border that follows your mouse around */
.home-page .feature-item:hover::before, .home-page .method-item:hover::before {
    opacity: 1;
}

/* feature item styles */
.home-page .feature-item h3, .home-page .method-item h3 {
  margin-bottom: 20px;
  color: #0E2B2C;
  font-size: 1.5rem;
  font-weight: 600;
}

.home-page .feature-item h3 .feature-highlight {
  display: inline-block;
  transition: font-size 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.home-page .feature-item:hover h3 .feature-highlight {
  font-size: 2rem;
}

/* fido logo */
.home-page .fido-logo {
    max-width: 50px;
    height: auto;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* how it works section css */

.home-page .steps {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 60px;
}

.home-page .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

/* handles styling of step content and create outline over the step content */
.home-page .step-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  padding: 3px;
  margin-bottom: 17px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  border-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 255, 255, 0) 100%) 1;
}
/* layers on the outline above to make it show up as a partial outline via the radial gradient */
.home-page .step-content::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: 12px;
  background: radial-gradient(
    circle at var(--gradient-x, 78%) var(--gradient-y, -410%),
    #0E2B2C 0%,
    #0E2B2C 80%,
    transparent 80.1%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  padding: 3px;
}
/* handles the image for the step */
.home-page .step-image {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  /* this does the hover animation for us (with help from some javascript) where one fades out for the other */
  .home-page .face-id-image,
  .home-page .face-id-success-image {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
  
  .home-page .face-id-image {
    z-index: 2;
  }
  
  .home-page .face-id-success-image {
    z-index: 1;
  }
  
  .home-page .hidden {
    opacity: 0;
  }
/* basic image styling for the step */
.home-page .step-image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: contain;
}
/* styling for the step number */
.home-page .step-number {
  background-color: #0E2B2C;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  margin-right: 15px;
  flex-shrink: 0;
}
/* styling for the step description */
.home-page .step p {
  font-size: 1rem;
  color: #0E2B2C;
  margin: 0;
  text-align: left;
  flex-grow: 1;
  padding: 0 2px;
}
/* media query for larger screens for step*/
@media (min-width: 1024px) {
  .home-page .steps {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }

  .home-page .step {
    flex: 1;
    max-width: 300px;
  }
}

/* how it works section css */
.home-page .how-it-works {
    /* background-color: #fff; */
    position: relative;
    overflow: hidden;
  }

/* frosty glass background for the how it works section tile */
.home-page .frosted-glass {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 60px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.home-page .how-it-works .container {
  position: relative;
  z-index: 1;
}

.home-page .how-it-works h2 {
  color: #0E2B2C;
}

/* testimonials section css */
.home-page .testimonials {
    background-color: #002C2C;
    position: relative;
    overflow: hidden;
  }
/* semicircle arch for the testimonials section */
  .home-page .testimonials::before {
    content: '';
    position: absolute;
    top: -10vh;
    left: -10%;
    width: 120%;
    height: 30vh;
    background-color: #D4EBD8;
    border-radius: 0 0 50% 50%;
    z-index: 0;
  } 
/* frosty glass background for the testimonial item */
.home-page .testimonial-item {
  background: rgba(255, 255, 255, 0.90);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  font-style: italic;
  text-align: center;
}
/* styling for the cite of the testimonial cite */
.home-page .testimonial-item cite {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  color: #0E2B2C;
}

/* another button styling */
.home-page .cta {
  background-color: #002C2C;
  text-align: center;
  color: #D8EAD9
}

.home-page .cta h2 {
  color: #D8EAD9;
}

/* New media query for screens between mobile and 1065px */
@media (max-width: 1064px) and (min-width: 1024px) {
    .home-page .feature-item h3 {
      font-size: 1.3rem; /* Reduced size for heading */
    }
  
    .home-page .feature-item p {
      font-size: 1.1rem; /* Reduced size for paragraph */
    }
  }

@media (max-width: 1023px) and (min-width: 533px) {
    .home-page .feature-item h3 {
        font-size: 1.3rem; /* Reduced size for heading */
      }

    .home-page .feature-item {
      height: 300px;
    }
  
    .home-page .feature-item p {
      font-size: 0.9rem; /* Reduced size for paragraph */
    }
  }

@media (max-width: 768px) {
  .home-page .hero h1 {
    font-size: 2.5rem;
  }

  .home-page .steps {
    flex-direction: column;
  }

  .home-page .step {
    margin-bottom: 40px;
  }

  .home-page .method-grid {
    grid-template-columns: 1fr;
  }

  .home-page .method-item {
    height: auto;
    min-height: 350px;
  }
}

/* smooth scrolling */
html {
    scroll-behavior: smooth;
  }
  
  body {
    overflow-x: hidden;
  }
  
  .home-page .hero, .home-page section {
    min-height: auto;
    display: flex;
    align-items: center;
    padding: 80px 0;
  }

/* bouncing Scroll Arrow */
.home-page .scroll-arrow {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 20px;
    height: 20px;
    border-left: 2px solid #B8D8BC;
    border-bottom: 2px solid #B8D8BC;
    cursor: pointer;
    animation: arrowBounce 2s infinite;
    z-index: 10;
    margin: auto;
}
  /* animation for the bouncing scroll arrow */
@keyframes arrowBounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translate(-50%, 0) rotate(-45deg);
    }
    40% {
        transform: translate(-50%, -5px) rotate(-45deg);
    }
    60% {
        transform: translate(-50%, -2.5px) rotate(-45deg);
    }
}
/* media query for smaller screens */
  @media (max-width: 450px) {
    .home-page .hero h1 {
      font-size: 2.2rem; /* Reduced from 3.5rem */
    }
  
    .home-page .hero p {
      font-size: 1rem; /* Reduced from 1.3rem */
    }
  
    .home-page .frosted-glass {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 30px;
      padding-bottom: 30px;
     
    }
  
    .home-page .cta-button {
      padding: 12px 24px; /* Slightly reduced padding */
      font-size: 1rem; /* Slightly reduced font size */
    }
  
    .home-page .section-title {
      font-size: 1.8rem; /* Reduced from 2rem */
    }
  
    .home-page .feature-item, .home-page .testimonial-item, .home-page .method-item {
      padding: 30px; /* Reduced from 40px */
    }
  }